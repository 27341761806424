import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackageItineraryReview } from "./component";
import {
  getFareTripDetailsById,
  getPackagesByReturnFareSlice,
  getSelectedTrip,
} from "../flight-shop/reducer";
import { finalizePackage } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedTrip: getSelectedTrip(state),
    tripDetailsById: getFareTripDetailsById(state),
    getPackagesByReturnFareSlice: getPackagesByReturnFareSlice(state),
  };
};

export const mapDispatchToProps = { finalizePackage };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackageItineraryReviewConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackageItineraryReview = withRouter(
  connector(PackageItineraryReview)
);
