import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { PackageItineraryReviewConnectorProps } from "./container";

interface IPackageItineraryReviewProps
  extends PackageItineraryReviewConnectorProps {}

export const PackageItineraryReview = ({
  finalizePackage,
}: IPackageItineraryReviewProps) => {
  useEffect(() => {
    finalizePackage();
  }, []);

  return <Box>Trip review?</Box>;
};
